.background{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 80px 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 80px 100%);
}

@media (max-width: 767px) {
    .background{
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 26% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 26% 100%);
    }
}

.background2{
    -webkit-clip-path: polygon(0 0, 100% 0%, 65% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0%, 65% 100%, 0 100%);
}