@import url('https://fonts.googleapis.com/css2?family=Boogaloo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Poppins', sans-serif;
}

ol{
  list-style: decimal;
}

.rectangle{
  -webkit-clip-path: polygon(0 0, 45% 0, 40% 100%, 0 100%);
clip-path: polygon(0 0, 45% 0, 40% 100%, 0 100%);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-title > span{
  font-family: 'Boogaloo', cursive;
  font-size: calc(8000vw / 1440);
  min-width: max-content;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  line-height: 1;
  display: inline-block;
  transform: translateY(100%);
  animation: titleFadeIn 0.5s ease-in-out forwards;
}

#hero-image {
  transform: translateX(35%);
  opacity: 0;
  animation: imageFadeIn 0.6s ease-in-out forwards;
}

@keyframes imageFadeIn {
  0% {
    transform: translateX(35%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

#hero-button {
  background-color: #303030;
  animation: buttonFadeIn 0.5s ease-in-out forwards;
  animation-delay: 0.75s;
}


@keyframes buttonFadeIn {
  0% {
    background-color: #303030;
  }
  100% {
    background-color: #F2C879;
  }
}



@keyframes titleFadeIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.hero-title-first > span {
  animation-delay: 0.2s ;
}

.hero-title-second > span {
  animation-delay: 0.35s;
}


.hero-title{
  font-family: 'Boogaloo', cursive;
  font-size: calc(8000vw / 1440);
  min-width: max-content;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  line-height: 1;
  overflow: hidden;
}

.hidden-title{
  position: absolute;
  font-family: 'Boogaloo', cursive;
  font-size: calc(8000vw / 690);
  min-width: max-content;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  line-height: 1;
  top: 42.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: -webkit-linear-gradient(-90deg, #303030 0%, rgba(48, 48, 48, 0.17) 40.63%, rgba(255, 255, 255, 0.1) 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 10px transparent;
  color: #303030;
}

#hero-description{
  animation: descriptionFadeIn 0.5s ease-in-out forwards;
  opacity: 0;
  animation-delay: 0.6s;
}

@keyframes descriptionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 1728px) {
  
  .hidden-title {
    font-size: 200px;
  }

  h1.hero-title {
    font-size: 96px;
 }

 h1.hero-title span {
  font-size: 96px;
  }

}

@media (max-width: 768px){

  h1.hero-title {
    font-size: calc(8000vw / 790);
  }

 h1.hero-title span {
  font-size: calc(8000vw / 790);
  }
  
  #hero-image {
    animation-delay: 0.35s;
  }
  
  #hero-button {
    background-color: #303030;
    animation: buttonFadeIn 0.5s ease-in-out forwards;
    animation-delay: 0.75s;
  }
  
  .hero-title-first > span {
    animation-delay: 0s ;
  }
  
  .hero-title-second > span {
    animation-delay: 0.20s;
  }
  
  #hero-description{
    animation: descriptionFadeIn 0.5s ease-in-out forwards;
    opacity: 0;
    animation-delay: 0.6s;
  }
}